<template>
  <div class="container-fluid" id="company-section">
    <div class="box-1 box-top">
      <div class="box-2">
        <div class="row no-gutters ">
          <div
            class="col-12 col-sm-12 col-md-12 col-lg-12 "
            style="margin-bottom: 30px"
          >
            <div class="box-3 box-3-nomargin">
              <h3>Our Services</h3>
            </div>
          </div>

          <div class="row no-gutters our-services-content">
            <div
              class="col-12 col-sm-6 col-md-4 col-lg-4 service-title d-none d-md-block"
            >
              <div class="box-3 box-3-nomargin services-list-container-desktop">
                <ul>
                  <li
                    v-for="item in user"
                    :key="item"
                    :class="active === item.name ? 'active' : 'non-active'"
                    v-on:click="setActived(item)"
                  >
                    <img :src="getImgUrl(item.menuIcon)" alt="menu icon" />
                    {{ item.name }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="row d-flex d-md-none">
              <div
                v-for="item in user"
                :key="item"
                :class="
                  active === item.name
                    ? 'active-card-mobile'
                    : 'non-active-card-mobile'
                "
                class="col-4 service-title"
                v-on:click="setActived(item)"
              >
                <div class="box-3 box-3-nomargin card-shape">
                  <div class="noise-overlay"></div>
                  <img
                    v-bind:src="getImgUrl(item.menuIcon)"
                    class="icons"
                    v-bind:alt="item.name"
                  />
                  <span>{{ item.name }}</span>
                </div>
              </div>
            </div>

            <div
              class="col-12 col-sm-12 col-md-4 col-lg-4 service-image d-flex justify-content-center align-items-center"
            >
              <img :src="getImgUrl(imageUrl)" class="center-image" />
            </div>
            <div
              class="col-12 col-sm-12 col-md-4 col-lg-4 p-0 service-description"
            >
              <div class="box-3 box-3-nomargin">
                <p v-html="description"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: [
        {
          name: 'WEB & APP DEVELOPMENT',
          menuIcon: 'our-services-icon-web-app.svg',
          image: 'our-service-web-app.png',
          description:
            'Anyone who’s participated in the art of software development knows that code/release/repeat isn’t the way. Our team of developers builds applications and websites that are better than that, wielding cutting-edge technology as our sword and Agile best practices as our shield.<br><br> From concept, to prototype, to full-fledged product, our skilled development team works iteratively to yield innovative products, custom-made to grow along with organisations long after we’ve hit the ‘live’ button. ',
        },
        {
          name: 'blockchain development',
          menuIcon: 'our-services-icon-blockchain.svg',
          image: 'our-service-blockchain.png',
          description:
            'The transformative impact of blockchain technology unlocks untold amounts of economic opportunity, and the adoption and deployment of blockchain solutions is in full swing. <br><br> Our approach to Blockchain project development not only requires us to know programming languages, but also to have a deep understanding of decentralized apps and cryptography principles so practical solutions are applied and projects maximize the power of decentralization.',
        },
        {
          name: 'UX/UI Design',
          menuIcon: 'our-services-icon-ui-ux.svg',
          image: 'our-service-ui-ux.png',
          description:
            'New challenges, changing needs—this is the lay of the land for any ambitious organisation in the digital realm, and it all starts with design. <br><br> Our team crafts world-class products and services across a range of industries. From user experience design, to user interface, to web design, to enterprise UX, we work closely with our clients across the world to enrich the customer journey. <br><br> Our ultimate goal is to build transformative digital experiences—via Design Thinking—across all touchpoints of organisations.',
        },
        {
          name: 'Dev Ops',
          menuIcon: 'our-services-icon-devops.svg',
          image: 'our-service-devops.png',
          description:
            'As a partnership between software development and IT operations teams, businesses that integrate DevOps deliver increased value, more responsiveness, and swift service delivery. <br><br> The operating principle that raises the bar for DevOps teams is the CI/CD pipeline (continuous integration/continuous delivery), enabling teams to make code changes more reliably and more often. <br><br> That doesn’t mean working harder but smarter. The ultimate goal here is automated testing, moving your team away from manual time-killing procedures, towards high-level, business-focused decision making.',
        },
        {
          name: 'Digital Transformation',
          menuIcon: 'our-services-icon-digital-transformation.svg',
          image: 'our-service-digital-transformation.png',
          description:
            'New products, services and communication platforms can bring tremendous improvement to your value chain, but those benefits will be lost in the process if cultural change doesn’t sit at the top of your to-do list. <br><br> The anatomy of digital transformation has its roots in governance and technology leadership. From information services, to mircroarchitecture, to DevOps and automation practices, having a clear course of action and a set of tools in place is the first step in shifting business operations towards shorter time to market, virtualisation, less manual and more automated processes, and a culture based on innovation.',
        },
        {
          name: 'Innovation Lab',
          menuIcon: 'our-services-icon-innovation-lab.svg',
          image: 'our-service-innovation-lab.png',
          description:
            "With a strong focus on ecosystems and partnerships, our Innovation Lab is a collaborative sandbox where our team builds stronger capabilities around emerging technologies. <br><br> Taking tangible steps to address society's most pressing issues, our ultimate goal is to work with partners, clients and like-minded entities who are ready to deliver cutting-edge solutions that will benefit both business and the world.",
        },
      ],
      active: 'WEB & APP DEVELOPMENT',
      menuIcon: 'our-services-icon-web-app.svg',
      imageUrl: 'our-service-web-app.png',
      rectangle: '../../assets/rectangle.svg',
      description:
        'Anyone who’s participated in the art of software development knows that code/release/repeat isn’t the way. Our team of developers builds applications and websites that are better than that, wielding cutting-edge technology as our sword and Agile best practices as our shield.<br><br>From concept, to prototype, to full-fledged product, our skilled development team works iteratively to yield innovative products, custom-made to grow along with organisations long after we’ve hit the ‘live’ button. ',
    };
  },
  methods: {
    setActived(data) {
      this.active = data.name;
      this.imageUrl = data.image;
      this.description = data.description;
    },
    getImgUrl(data) {
      var images = require.context('../../assets/', false);
      return images('./' + data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/OurServices.scss';

.our-services-content {
  overflow-y: auto;
  height: 65vh;

  @media only screen and (min-width: 768px) {
    height: auto;
  }
}

.card-shape {
  background-image: url('../../assets/card-shape.png');
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 4/5;
  position: relative;
  width: 100.51px;
  height: 125.78px;
  margin: auto;
  margin-bottom: 20px;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;

  img.icons {
    width: 59.26px;
  }

  span {
    color: white;
    line-height: 14.4px;
    font-size: 13px;
    font-family: 'Bebas Kai';
    letter-spacing: 0.05em;
  }

  .noise-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/noise.png');
    border-radius: 24px;

    @media only screen and (min-width: 1440px) {
      border-radius: 1.6666666666666667vw;
    }
  }

  span {
    color: white;
  }
}
.service-image {
  order: 1;

  @media only screen and (min-width: 768px) {
    order: 0;
  }
}
.active-card-mobile {
  filter: drop-shadow(1.852px 1.852px 9.26px rgba(51, 255, 255, 0.25));
  transition: all ease 0.5s;

  span {
    color: #33ffff;
  }
}
.non-active-card-mobile {
  filter: none;
}
/* .box-top {
  padding-top: 80px !important;

  @media only screen and (min-width: 768px) {
    padding-top: 10.277777777777779vw !important;
  }
} */
.box-top {
  margin-top: 70px !important;

  @media only screen and (min-width: 768px) {
    /* margin-top: 10.277777777777779vw !important; */
    margin-top: 6.111111111111111vw !important;
    padding-top: 3.4vw !important;
  }
}
.services-list-container-desktop {
  overflow-y: auto;
  margin-top: 2.0833333333333335vw;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  @media only screen and (max-height: 600px) {
    height: 53.385416666666664vh;
  }

  /* Scrollbar */

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    margin-left: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    // background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.05);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(85, 85, 85, 0.05);
  }
}
</style>
