let state = () => {
  return {
    wct2_opacity_0: false,
    speechSearch: false,
    prevRoute: null,
    wct1_doAnimation: true,
    wc_t1_opacity_0: false,
    searchBoxEnable: true,
    WelcomeMessage: true,
    wct2_doAnimation: false,
    wct2_doAnimation_delay: true,
    sbox_doAnimation: false,
    sbox_doAnimation_delay: true,
    searchCircle: true,
    isMenuOpen: false,
  };
}

export default state;
