<template>
  <!-- <div id="header">
    <img
      id="message-circle"
      src="../assets/chat.svg"
      v-if="!searchCircle"
      v-on:click="openSearchBox(), toggleMenu(false)"
    />
    <img
      id="x-circle"
      src="../assets/x-circle-2.svg"
      v-if="searchCircle"
      v-on:click="openSearchBox(), toggleMenu(false)"
    />
    <img
      @click="toggleMenu(), openSearchBox(false)"
      class="burger-menu"
      v-if="isMenuOpen == false"
      src="../assets/burger-menu-2.svg"
    />

    <img
      id="x-circle"
      src="../assets/x-circle-2.svg"
      v-else
      v-on:click="toggleMenu()"
    />
  </div> -->

  <div
    class="container home"
    :class="{ 'hide-container': isMenuOpen }"
    v-if="searchBoxEnable"
  >
    <div class="row blur-box">
      <div class="col-12 text-center wc-t1-col">
        <div class="wc-t1-typewidth">
          <div v-show="WelcomeMessage" style="display: inline-block;">
            <span
              :class="{ 'wc-t1-hide': !WelcomeMessage }"
              class="wc-t1 wc-t1-animate carret-blink"
              v-html="finalWord"
            ></span>
          </div>
          <div
            v-show="!WelcomeMessage"
            :class="{
              'opacity-0': wct2_opacity_0,
            }"
            style="display: inline-block;"
          >
            <span
              :class="{
                'wc-t2-animate': wct2_doAnimation_delay || wct2_doAnimation,
              }"
              class="wc-t2"
              >How can we help?</span
            >
          </div>
        </div>
      </div>

      <div
        v-show="!WelcomeMessage"
        class="col-12 search-box"
        :class="{
          'search-box-animate': sbox_doAnimation_delay || sbox_doAnimation,
        }"
      >
        <input
          class=""
          v-model="searchBoxInput"
          @keyup.enter="submitSearch()"
        />
        <!-- <img
          id="mic"
          src="../assets/mic.svg"
          v-if="!speechSearch"
          v-on:click="doSpeechSearch(true)"
        /> -->
        <img id="audio" src="../assets/audio.svg" v-if="speechSearch" />
      </div>
      <div class="col-12 text-center error-message" v-if="errorSearch">
        I couldn't find that, please try another search
      </div>
      <div class="col-12 wc-t3-audio-typewidth" v-if="speechSearch">
        <span class="wc-t3-audio">Go ahead, I’m listening.</span>
      </div>
    </div>
  </div>
  <div
    :class="{
      'search-result-animate': sbox_doAnimation,
      'search-result-animate-delay': sbox_doAnimation_delay,
    }"
    class="container-fluid"
    id="company-section"
  >
    <div class="box-1">
      <div class="box-2">
        <div class="row no-gutters align-items-center justify-content-center">
          <div
            class="col-6 col-sm-6 col-md-3 col-lg-3"
            v-for="(item, index) in dataSearch"
            :key="item"
            v-show="index < 4"
          >
            <router-link @click="openSearchBox(false)" :to="item.path">
              <div class="box-3 box-3-nomargin">
                <div class="noise-overlay"></div>
                <img
                  v-bind:src="getImgUrl(item.icon)"
                  class="icons"
                  v-bind:alt="item.meta.itemTitle"
                />
                <span>{{ item.meta.itemTitle }}</span>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

import { routes } from '../router/index';
import store from '../store';

export default {
  name: 'Home',
  data: function() {
    return {
      dataSearch: [],
      searchBoxInput: '',
      errorSearch: false,
      i: 0,
      txt: 'Hi there, welcome to Undercurrent Technologies.',
      newTxt: this.txt,
      finalWord: '',
      speed: 50,
    };
  },
  computed: {
    ...mapState([
      'wct2_opacity_0',
      'speechSearch',
      'prevRoute',
      'wct1_doAnimation',
      'wc_t1_opacity_0',
      'searchBoxEnable',
      'WelcomeMessage',
      'wct2_doAnimation',
      'wct2_doAnimation_delay',
      'sbox_doAnimation',
      'sbox_doAnimation_delay',
      'searchCircle',
      'isMenuOpen',
    ]),
    ...mapGetters(['searchBoxEnable', 'WelcomeMessage']),
  },
  props: ['searchBoxInactive'],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      store.commit('SET_PREV_ROUTE', from);
    });
  },
  created() {
    // this.detectScreenAndChangeText();
    setTimeout(() => store.commit('SET_WELCOME_MESSAGE', false), 9000);
  },
  mounted() {
    if (this.prevRoute.fullPath === null || this.prevRoute.fullPath !== '/') {
      this.$store.commit('SET_ANIMATION_HOME_TRUE');
    } else {
      this.$store.commit('SET_ANIMATION_HOME_FALSE');
    }

    setTimeout(this.typeWriter, 0);
    setTimeout(this.removeChar, 5000);
  },
  methods: {
    ...mapActions(['toggleMenu', 'doSpeechSearch', 'openSearchBox']),
    getImgUrl(data) {
      var images = require.context('../assets/', false);
      return images('./' + data);
    },
    submitSearch() {
      if (this.searchBoxInput === '') {
        this.dataSearch = [];
        this.errorSearch = false;
      } else {
        const result = routes.filter((data) => {
          if (data.meta.show === false) {
            return;
          }

          let searchText = this.searchBoxInput.toLowerCase();
          let regex = new RegExp(`\\b${searchText}\\b`, 'gi');
          console.log(regex);
          console.log(data.meta.keywords);

          // let regex = new RegExp(`${searchText}\\b`, 'gi');
          // if the keywords in a array data type
          // let found;
          // let final;
          // data.meta.keywords.forEach((keyword) => {
          //   found = keyword.match(regex);
          //   if (found != null || found != undefined) {
          //     final = found;
          //   }
          // });
          // return !!final;

          // if the keywords in a string data type
          return data.meta.keywords.match(regex);

          // return (
          //   this.searchBoxInput
          //     .toLowerCase()
          //     .split(' ')
          //     .every((v) => data.meta.title.toLowerCase().includes(v)) ||
          //   this.searchBoxInput
          //     .toLowerCase()
          //     .split(' ')
          //     .every((v) => data.meta.group.toLowerCase().includes(v))
          // );
        });
        this.dataSearch = result;
        this.errorSearch = result.length === 0;
      }
    },
    typeWriter() {
      if (this.i < this.txt.length) {
        this.newTxt = this.txt;
        this.finalWord += this.txt.charAt(this.i);
        this.i++;
        setTimeout(this.typeWriter, this.speed);
      }
    },
    removeChar() {
      if (this.i >= this.newTxt.length) {
        this.newTxt = this.newTxt.substring(0, this.newTxt.length - 1);
        this.finalWord = this.newTxt;
        this.i--;
        setTimeout(this.removeChar, this.speed);
      }
    },
    // detectScreenAndChangeText() {
    //   if (screen.width >= 552) {
    //     this.txt = 'Hi there, welcome to Undercurrent Technologies.';
    //   } else {
    //     this.txt = 'Hi there, welcome to <br/> Undercurrent Technologies.';
    //   }
    // },
  },
  watch: {
    // searchBoxInput: function() {
    //   if (this.searchBoxInput === '') {
    //     this.dataSearch = [];
    //     this.errorSearch = false;
    //   } else {
    //     const result = routes.filter((data) => {
    //       return (
    //         this.searchBoxInput
    //           .toLowerCase()
    //           .split(' ')
    //           .every((v) => data.meta.title.toLowerCase().includes(v)) ||
    //         this.searchBoxInput
    //           .toLowerCase()
    //           .split(' ')
    //           .every((v) => data.meta.group.toLowerCase().includes(v))
    //       );
    //     });
    //     this.dataSearch = result;
    //     this.errorSearch = result.length === 0;
    //   }
    // },
    searchBoxEnable: function(val) {
      if (!val) {
        this.searchBoxInput = '';
        this.dataSearch = [];
        this.errorSearch = false;
      }
    },
    WelcomeMessage: function(val) {
      if (val) {
        // this.detectScreenAndChangeText();

        this.finalWord = '';
        setTimeout(this.typeWriter, 0);
        setTimeout(this.removeChar, 5000);
        setTimeout(() => store.commit('SET_WELCOME_MESSAGE', false), 9000);
        this.searchBoxInput = '';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../assets/styles/Home.scss';
.error-message {
  font-family: Fira Code;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 26px;
  text-align: justify;
  letter-spacing: 0.05em;
  margin-top: 5rem;
  color: #ffffff;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 1.3888888888888888vw;
    line-height: 1.7097222222222221vw;
    margin-top: 5.555555555555555vw;
  }
}
.box-2 a {
  text-decoration: none !important;
}

.container {
  transition: all ease 0.5s;

  &.hide-container {
    opacity: 0;
    z-index: -15;
  }

  &.home {
    margin-top: calc(5vh + 55px);

    @media only screen and (min-width: 992px) {
      margin-top: calc(5vh + 6.111111111111111vw);
    }
  }
}

#company-section {
  .box-1 {
    padding-bottom: 100px !important;

    @media only screen and (min-width: 1440px) {
      padding: 0 120px;
    }

    .box-2 {
      .row {
        padding-right: 6px;
        padding-left: 6px;

        @media only screen and (min-width: 768px) {
          padding-right: 12px;
          padding-left: 12px;
        }

        .col-6.col-sm-6.col-md-3.col-lg-3 {
          padding-right: 6px;
          padding-left: 6px;

          @media only screen and (min-width: 768px) {
            padding-right: 12px;
            padding-left: 12px;
          }
        }
      }
    }

    .box-3 {
      background-image: url('../assets/card-shape.png');
      background-size: cover;
      background-repeat: no-repeat;
      aspect-ratio: 4/5;
      position: relative;
      height: 100%;

      .noise-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('../assets/noise.png');
        border-radius: 24px;

        @media only screen and (min-width: 1440px) {
          border-radius: 1.6666666666666667vw;
        }
      }
    }
  }
}
</style>
