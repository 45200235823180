<template>
  <div class="container-fluid" id="company-section">
    <div class="box-1 box-top">
      <div class="box-2">
        <div class="row no-gutters">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 ">
            <div class="box-3 box-3-nomargin">
              <h3>Contact Us</h3>
            </div>
          </div>
          <!-- <div
            class="col-12 col-sm-12 col-md-12 col-lg-12"
            style="margin-top: -10.277777777777779vw;"
          > -->
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <div class="box-3 box-3-nomargin text-center">
              <div class="contact-container">
                <h4 class="contact-title">Email</h4>
                <h4 class="contact-description">
                  <a href="mailto:hello@undercurrent.tech"
                    >hello@undercurrent.tech</a
                  >
                </h4>
              </div>
              <div class="contact-container">
                <h4 class="contact-title">Telephone</h4>
                <h4 class="contact-description">
                  <a href="tel:+6598270660">+65 9827 0660</a>
                </h4>
              </div>
              <div class="contact-container">
                <h4 class="contact-title">Address</h4>
                <h4 class="contact-description">
                  138 Robinsons road, lobby 1, 29th Floor, Singapore 068906
                </h4>
              </div>
            </div>
          </div>
          <!-- <div
            class="col-12 col-sm-12 col-md-12 col-lg-12 d-flex"            
          >
            <div class="box-3 box-3-nomargin box-4">
              <h3>Contact us</h3>
            </div>
          </div> -->
          <!-- <div
            class="col-12 col-sm-12 col-md-12 col-lg-12 "
            style="height: calc(100vh - 178px);"
          >
            <div class="box-3 box-3-nomargin phone-image">
              <!- <img src="../../assets/contact.svg" class="bg-phone"/> ->
              <img src="../../assets/contact-us-3.png" class="bg-phone" />
              <div class="full-contact">
                <div class="row m-0">
                  <div class="col-6 c-btn">
                    <a href="tel:+6598270660">
                      <button class="btn btn-contact">
                        <img
                          src="../../assets/call.svg"
                          style="width: 10px; height: 10px;"
                        />
                        <br />
                        call
                      </button>
                    </a>
                  </div>
                  <div class="col-6 c-btn">
                    <a href="mailto:hello@undercurrent.tech">
                      <button class="btn btn-contact">
                        <img
                          src="../../assets/mail.svg"
                          style="width: 10px; height: 10px;"
                        />
                        <br />
                        mail
                      </button>
                    </a>
                  </div>
                </div>
                <div class="row ads-texts">
                  <div class="col-12 p-0">
                    <div class="title-ads">Contact</div>
                    <div class="sub-ads">+65 9827 0660</div>
                  </div>
                  <div class="col-12 p-0">
                    <div class="line-ads"></div>
                  </div>
                  <div class="col-12 p-0">
                    <div class="title-ads">Email</div>
                    <div class="sub-ads">hello@undercurrent.tech</div>
                  </div>
                </div>
                <div class="row ads-texts">
                  <div class="col-6 p-0">
                    <div class="title-ads">Office Address</div>
                    <div
                      class="sub-ads"
                      style="color:#000000 !important;font-size: 0.625vw !important;"
                    >
                      138 Robinson Road Lobby 1, 29th Floor Singapore 068906
                    </div>
                  </div>
                  <div class="col-6 p-0">
                    <div class="map-ads">
                      <iframe
                        id="ucc-maps"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15955.299348970188!2d103.8484196!3d1.278646!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7b39de0bcda5cbfd!2sOxley%20Tower!5e0!3m2!1sid!2sid!4v1628715118245!5m2!1sid!2sid"
                        class="maps-iframe"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        aria-hidden="false"
                        tabindex="0"
                      >
                      </iframe>
                    </div>
                  </div>
                </div>
                <div class="row ads-texts notes-c">
                  <div class="col-12 p-0">
                    <div class="title-ads">Notes</div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import '../../assets/styles/Contact.scss';
.text-head-cont {
  position: absolute;
  top: 7%;
  left: 15%;
  font-family: 'Bebas Kai';
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 50.4px;
  color: #ffffff;

  @media only screen and (min-width: 992px) {
    font-size: 3.4722222222222223vw;
    line-height: 4.166666666666667;
  }
}
.ads-texts {
  position: relative;
  margin: 8px 5px 0;
  padding: 5px 0 5px 15px;
  background-color: #ffffff;
  border-radius: 0.3rem;
  z-index: 1;

  @media only screen and (min-width: 1440px) {
    padding: 0 0.3472222222222222vw !important;

    margin: 0.5555555555555556vw 0.3472222222222222vw 0;
    padding: 0.3472222222222222vw 0 0.3472222222222222vw 1.0416666666666667vw;
  }
}
.title-ads {
  font-size: 8px;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  width: 100%;
  //margin-bottom: 3px;
  text-align: left;

  @media only screen and (min-width: 1440px) {
    font-size: 0.5555555555555556vw;
  }
}
.sub-ads {
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  color: #007aff;
  width: 100%;
  text-align: left;

  @media only screen and (min-width: 1440px) {
    font-size: 0.6944444444444444vw;
  }
}
.line-ads {
  width: 100%;
  height: 1px;
  margin: 0.3472222222222222vw 0;
  background-color: #dbdcdd;
}
.map-ads {
  position: relative;
  min-height: 60px;
  width: 100%;
  height: 100%;
}
.maps-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  //right: 0;
  min-height: 60px;
  width: 95%;
  height: 100%;
}
.c-btn {
  padding: 0 5px !important;

  @media only screen and (min-width: 1440px) {
    padding: 0 0.3472222222222222vw !important;
  }
}
.btn-contact,
.btn-contact:hover,
.btn-contact:focus,
.btn-contact:active {
  width: 100%;
  padding: 2px 1rem;
  font-size: 8px;
  font-style: normal;
  font-weight: bold;
  border-radius: 0.3rem;
  background-color: #ffffff;
  border-color: #ffffff;
  color: #007aff;
  box-shadow: none !important;
  outline: transparent !important;

  @media only screen and (min-width: 1440px) {
    padding: 0.1388888888888889vw 1rem;
    font-size: 0.5555555555555556vw;
  }
}
.phone-image {
  text-align: center;
  //margin-top: 88px;
  // background-image: url(/img/phone-contact.58987270.svg);
  // background-repeat: no-repeat;
  // background-position: bottom -1px center;
  // height: calc(100vh - 88px);
  // background-size: 308px 545.14px;
  //background-size: 100% 100%;
}

.contact-container {
  margin-bottom: 40px;

  .contact-title {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;

    @media only screen and (min-width: 1440px) {
      font-size: 1.25vw;
      line-height: 1.25vw;
    }

    @media only screen and (min-width: 2560px) {
      font-size: 1.25vw;
      line-height: 1.25vw;
    }
  }

  .contact-description,
  .contact-description a {
    font-family: 'Bebas Kai';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ffffff;

    @media only screen and (min-width: 1440px) {
      font-size: 1.25vw;
      line-height: 1.25vw;
    }

    @media only screen and (min-width: 2560px) {
      font-size: 1.45vw;
      line-height: 1.45vw;
    }
  }
}
/* .box-top {
  padding-top: 80px;

  @media only screen and (min-width: 992px) {
    padding-top: 10.277777777777779vw;
  }
} */
.box-top {
  margin-top: 70px !important;

  @media only screen and (min-width: 768px) {
    /* margin-top: 10.277777777777779vw !important; */
    margin-top: 6.111111111111111vw !important;
    padding-top: 3.4vw !important;
  }
}
</style>
