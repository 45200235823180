<template>
  <header class="headers">
    <router-link to="/" @click="openSearchBox(true), toggleMenu(false)">
      <img id="logo_img" src="./assets/logo.svg" />
    </router-link>
    <!-- <router-link to="/" v-if="this.$route.path !== '/'">
      <img id="x-circle" src="./assets/x-circle-2.svg" />
    </router-link> -->
    <div style="margin-right: 17px;">
      <router-link to="/">
        <img
          id="message-circle"
          src="./assets/chat.svg"
          v-if="!searchCircle"
          @click="openSearchBox(), toggleMenu(false)"
        />
      </router-link>

      <router-link to="/" v-if="this.$route.path !== '/'">
        <img id="x-circle" src="./assets/x-circle-2.svg" v-if="searchCircle" />
      </router-link>

      <div v-else style="display: inline-block;">
        <img
          id="x-circle"
          src="./assets/x-circle-2.svg"
          v-if="searchCircle"
          @click="openSearchBox(), toggleMenu(false)"
        />
      </div>

      <img
        @click="toggleMenu(), openSearchBox(false)"
        class="burger-menu"
        v-if="isMenuOpen == false"
        src="./assets/burger-menu.svg"
      />

      <img
        id="x-circle"
        class="burger-menu-close"
        src="./assets/x-circle-2.svg"
        v-else
        @click="toggleMenu()"
      />
    </div>
  </header>
  <video autoplay muted loop playsinline id="myVideo">
    <source src="./assets/bg.mp4" type="video/mp4" />
    Your browser does not support HTML5 video.
  </video>

  <menu-dropdown :class="{ 'menu-burger-hide': !isMenuOpen }" />

  <router-view></router-view>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import MenuDropdown from './components/MenuDropdown.vue';

export default {
  name: 'App',
  components: { MenuDropdown },
  computed: {
    ...mapState([
      'wct1_doAnimation',
      'wc_t1_opacity_0',
      'searchBoxEnable',
      'WelcomeMessage',
      'wct2_doAnimation',
      'wct2_doAnimation_delay',
      'sbox_doAnimation',
      'sbox_doAnimation_delay',
      'searchCircle',
      'isMenuOpen',
    ]),
  },
  methods: {
    ...mapActions(['toggleMenu', 'openSearchBox']),
  },
};
</script>

<style lang="scss">
body {
  background-color: #011827;
}
#myVideo {
  position: fixed;
  right: 0;
  bottom: -100px;
  min-width: 100%;
  min-height: 100%;
  z-index: -10;

  @media only screen and (min-width: 768px) {
    bottom: 0;
  }
}
@font-face {
  font-family: Tw Cen MT Condensed;
  src: url('./assets/fonts/tw_cen/Tw-Cen-MT-Condensed.woff');
}

@font-face {
  font-family: Fira Code;
  src: url('./assets/fonts/fira_code/woff/FiraCode-Regular.woff');
}

@font-face {
  font-family: TT Hoves;
  src: url('./assets/fonts/tt_hoves/TTHoves-Regular.woff');
}
@font-face {
  font-family: TT Hoves Thin;
  src: url('./assets/fonts/tt_hoves/TTHoves-Thin.woff');
}

@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/manrope/Manrope-ExtraLight.woff');
  font-weight: 200;
}
@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/manrope/Manrope-Light.woff');
  font-weight: 300;
}
@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/manrope/Manrope-Regular.woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Bebas Kai';
  src: url('./assets/fonts/bebas-kai/BebasKai-Regular.woff');
}
@font-face {
  font-family: 'Exo';
  src: url('./assets/fonts/exo/Exo-Regular.ttf');
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('./assets/fonts/BebasNeue-Thin.woff');
  font-weight: 200;
}
@font-face {
  font-family: 'Bebas Neue';
  src: url('./assets/fonts/BebasNeue-Light.woff');
  font-weight: 300;
}
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import './assets/styles/media-query.scss';
@import './assets/styles/App.scss';

.headers {
  width: 100%;
  /* height: 55px; */
  height: 60px;
  z-index: 10;
  /* background: #011827; */
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;

  @media only screen and (min-width: 1400px) {
    height: 6.111111111111111vw;
  }

  #x-circle {
    /* margin-right: 20px; */
  }
  a {
    font-size: 0;

    ~ div {
      font-size: 0;
    }
  }
}
</style>
