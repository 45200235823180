<template>
  <div class="menu-burger">
    <div>
      <ul>
        <router-link
          @click="toggleMenu(false)"
          v-for="(menu, index) in menus"
          :key="index"
          :to="menu.link"
        >
          <li>
            {{ menu.title }}
          </li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      menus: [
        {
          title: 'Who We Are',
          link: '/who-we-are',
        },
        {
          title: 'Our Services',
          link: '/our-services',
        },
        {
          title: 'Portfolio',
          link: '/portfolio',
        },
        {
          title: 'Team',
          link: '/meet-the-team',
        },
        {
          title: 'Contact Us',
          link: '/contact',
        },
      ],
    };
  },
  methods: {
    ...mapActions(['toggleMenu']),
  },
};
</script>

<style lang="scss" scoped>
.menu-burger {
  color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  margin-top: 55px;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.02) 30.21%,
    rgba(255, 255, 255, 0.1) 49.48%,
    rgba(255, 255, 255, 0.02) 69.27%,
    rgba(255, 255, 255, 0) 100%,
    rgba(255, 255, 255, 0) 100%
  );
  mix-blend-mode: normal;
  /* width: calc(100vw - 16.666666666666668vw); */
  /* width: calc(100vw - 5.666667vw); */
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all ease 0.5s;
  backdrop-filter: blur(30px);

  @media only screen and (min-width: 768px) {
    /* width: calc(100vw - 10.666667vw); */
    width: calc(100vw - 16.666666666666668vw);
  }

  @media only screen and (min-width: 1440px) {
    margin-top: 6.111111111111111vw;
  }

  > div {
    margin-top: -6.111111111111111vw;
  }

  &.menu-burger-hide {
    opacity: 0;
    z-index: -15;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    height: auto;

    li {
      font-family: 'Bebas Kai';
      font-style: normal;
      font-weight: normal;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #ffffff;
      /* text-shadow: 0px 5px 5px rgb(63 63 63 / 40%); */
      font-size: 24px;
      line-height: 28.8px;
      margin: 50px 0;

      @media only screen and (min-width: 1440px) {
        font-size: 2.2222222222222223vw;
        line-height: 2.6666666666666665vw;
        margin: 3.4722222222222223vw 0px;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
