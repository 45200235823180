<template>
  <div class="container-fluid" id="company-section">
    <div class="box-1 box-top">
      <div class="box-2">
        <div class="row no-gutters align-items-center ">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12 ">
            <div class="box-3 box-3-nomargin team-description">
              <h3>Meet The Team</h3>
              <p>
                A collective of seasoned engineers and innovative designers, we
                are experts at finding practical solutions to difficult
                problems. Through talented project teams, we grit and grind to
                turn dreams with huge potential into game-changing realities.
              </p>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-4 col-lg-4 ">
            <div
              class="box-3 box-3-nomargin d-none d-md-block team-list-container-desktop"
            >
              <ul>
                <li
                  v-for="item in user"
                  :key="item"
                  :class="active === item.name ? 'active' : 'non-active'"
                  v-on:click="setActived(item)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>

            <div class="d-flex d-md-none" id="mobile-team">
              <ul>
                <li
                  v-for="item in user"
                  :key="item"
                  v-on:click="setActived(item)"
                >
                  <div class="rectangle-image">
                    <img
                      v-bind:src="getImgUrl(item.image)"
                      class="inner-image"
                    />
                  </div>
                  <div class="person-info">
                    <h4>{{ item.name }}</h4>
                    <h6>{{ item.role }}</h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="col-12 col-sm-6 col-md-4 col-lg-4 d-none d-md-block"
            style="text-align:center;"
          >
            <div class="rectangle-image">
              <img v-bind:src="getImgUrl(image)" class="inner-image" />
            </div>
          </div>
          <div
            class="col-12 col-sm-12 col-md-3 offset-md-1 col-lg-3 offset-lg-1 d-none d-md-block m-auto"
          >
            <div class="box-3 box-3-nomargin">
              <h4>
                {{ active }}
                <a
                  :href="socmed.linkedin"
                  target="_blank"
                  style="display: none;"
                >
                  <img
                    src="../../assets/linkedin.svg"
                    class="socmed-linkedin"
                    alt="linkedin"
                  />
                </a>
              </h4>
              <h6>{{ role }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: [
        {
          name: 'Cerwyn Eliata',
          image: 'team-cerwyn.png',
          role: 'Full Stack Developer',
          socmed: {
            linkedin: 'http://linkedin.com/in/cerwyn/',
          },
        },
        {
          name: 'Charlie Kong',
          image: 'team-blank.png',
          role: 'Full Stack Developer',
          socmed: {
            linkedin: '',
          },
        },
        {
          name: 'Dawn Lim',
          image: 'team-dawn.png',
          role: 'UI/UX Designer',
          socmed: {
            linkedin: 'http://linkedin.com/in/dawnlimyufen/',
          },
        },
        {
          name: 'Ezequiel Ivancovsky',
          image: 'team-ezequiel.png',
          role: 'Software Development Manager',
          socmed: {
            linkedin: 'http://linkedin.com/in/ezequiel-ivancovsky/',
          },
        },
        {
          name: 'Garrison Goh',
          image: 'team-garrison.png',
          role: 'Software Development Manager',
          socmed: {
            linkedin: 'http://linkedin.com/in/garrison-goh/',
          },
        },
        {
          name: 'Giorgi Javrishvili',
          image: 'team-giorgi.png',
          role: 'Software Development Manager',
          socmed: {
            linkedin: 'http://linkedin.com/in/giorgi-javrishvili-405b394b/',
          },
        },
        {
          name: 'Jace Liew',
          image: 'team-jace.png',
          role: 'UI/UX Designer',
          socmed: {
            linkedin: 'http://linkedin.com/in/jace-liew-477a835/',
          },
        },
        {
          name: 'Jonathan Kay',
          image: 'team-jonathan.png',
          role: 'Chief Operating Officer',
          socmed: {
            linkedin: 'http://linkedin.com/in/jonathan-kay-26b67690/',
          },
        },
        {
          name: 'Leon Heng',
          image: 'team-leonheng.jpg',
          role: 'UI/UX Designer',
          socmed: {
            linkedin: '',
          },
        },
        {
          name: 'Low Rong Heng',
          image: 'team-rongheng.png',
          role: 'Full Stack Developer',
          socmed: {
            linkedin: 'http://linkedin.com/in/rongheng/',
          },
        },
        {
          name: 'Michael Knott',
          image: 'team-michael.png',
          role: 'Chief Technology Officer',
          socmed: {
            linkedin: 'http://linkedin.com/in/mike-knott-a76a241/',
          },
        },
      ],
      active: 'Cerwyn Eliata',
      image: 'team-cerwyn.png',
      role: 'Full Stack Developer',
      socmed: {
        linkedin: 'http://linkedin.com/in/cerwyn/',
      },
    };
  },
  methods: {
    setActived(data) {
      this.active = data.name;
      this.role = data.role;
      this.image = data.image;
      this.socmed.linkedin = data.socmed.linkedin;
    },
    getImgUrl(data) {
      var images = require.context('../../assets/', false);
      return images('./' + data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/MeetTheTeam.scss';

#mobile-team {
  padding: 0 22px;
  overflow-y: auto;
  height: calc(100vh - 270px);

  ul {
    padding: 0;
    li {
      display: flex;
      color: white;
      align-items: center;
      margin: 15px 0;

      .rectangle-image {
        margin: 0;
        padding: 2px;
        width: 120px !important;
        height: 120px !important;
        border-width: 4px;
        margin-right: 20px;
      }

      .person-info {
        display: flex;
        flex-direction: column;
        flex: 1;

        h4 {
          font-size: 16px;
          line-height: 21.86px;
          font-family: 'Manrope';
          font-weight: 700;
        }

        h6 {
          font-size: 10px;
          line-height: 13.66px;
          font-family: 'Manrope';
          font-weight: 700;
        }
      }
    }
  }
}
.team-list-container-desktop {
  overflow-y: auto;

  @media only screen and (max-height: 600px) {
    height: 39.0625vh !important;
  }

  @media only screen and (min-width: 992px) {
    height: 20.833333333333332vw;
  }

  /* Scrollbar */

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    margin-left: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    // background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.05);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(85, 85, 85, 0.05);
  }
}
/* .box-top {
  padding-top: 80px;

  @media only screen and (min-width: 992px) {
    padding-top: 10.277777777777779vw;
  }
} */
.box-top {
  margin-top: 70px !important;

  @media only screen and (min-width: 768px) {
    /* margin-top: 10.277777777777779vw !important; */
    margin-top: 6.111111111111111vw !important;
    padding-top: 3.4vw !important;
  }
}
.team-description {
  margin-bottom: 30px;

  @media only screen and (min-width: 768px) {
    /* margin-bottom: 5vw; */
    margin-bottom: 4.5vw;
  }
}
</style>
