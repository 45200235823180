import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
// import Project_Carfren from '../components/Project-carfren.vue'
// import Arcc from '../components/Arcc.vue'
// import Digital_Survey from '../components/DigitalSurvey.vue'
import About_Us from '../components/company/AboutUs.vue'
import Our_Services from '../components/company/OurServices.vue'
import MeetTheTeam from '../components/company/MeetTheTeam.vue'
import Contact from '../components/company/Contact.vue'
import Portfolio from '../components/company/Portfolio.vue'
import PortfolioDetail from '../components/PortfolioDetail.vue';


export const routes = [
         {
           path: '/',
           component: Home,
           props: true,
           meta: {
             title: 'Home - Undercurrent Tech',
             group: '',
             keywords: '',
             itemTitle: 'Home',
           },
           icon: 'menu-contact.png',
         },
         //  {
         //    path: '/carfren',
         //    component: Project_Carfren,
         //    meta: { title: 'Carfren' },
         //    icon: 'icon-carfren.svg',
         //  },
         //  {
         //    path: '/digitalsurvey',
         //    component: Digital_Survey,
         //    meta: { title: 'Digital Surveys' },
         //    icon: 'icon-arcc.svg',
         //  },
         {
           path: '/who-we-are',
           component: About_Us,
           meta: {
             title: 'Who We Are - Undercurrent Tech',
             group: 'company',
             keywords: 'vision values company profile mission',
             itemTitle: 'Who We Are',
           },
           icon: 'menu-who-we-are.png',
         },
         {
           path: '/our-services',
           component: Our_Services,
           meta: {
             title: 'Our Services - Undercurrent Tech',
             group: 'company',
             keywords:
               'web app development UI UX design dev ops digital innovation code blockchain cryptography solutions IT ecosystem',
             itemTitle: 'Our Services',
           },
           icon: 'menu-services.png',
         },
         {
           path: '/meet-the-team',
           component: MeetTheTeam,
           meta: {
             title: 'Meet The Team - Undercurrent Tech',
             group: 'company',
             keywords: 'team people developer designer managers',
             itemTitle: 'Meet The Team',
           },
           icon: 'menu-team.png',
         },
         {
           path: '/contact',
           component: Contact,
           meta: {
             title: 'Contact us - Undercurrent Tech',
             group: '',
             keywords:
               'contact, address, location, office, email, mobile, call, phone',
             itemTitle: 'Contact Us',
           },
           icon: 'menu-contact.png',
         },
         {
           path: '/portfolio',
           component: Portfolio,
           meta: {
             title: 'Portfolio - Undercurrent Tech',
             group: '',
             keywords: 'portfolio projects clients work',
             itemTitle: 'Portfolio',
           },
           icon: 'menu-portofolio-2.png',
         },
         {
           path: '/portfoliodetail',
           component: PortfolioDetail,
           meta: {
             title: 'Portfolio Detail - Undercurrent Tech',
             group: '',
             keywords: '',
             itemTitle: 'Portfolio Detail',
             show: false,
           },
           icon: 'menu-portofolio-2.png',
         },
         {
           path: '/portfoliodetail#arcc',
           component: PortfolioDetail,
           meta: {
             title: 'Arcc - Undercurrent Tech',
             group: 'projects',
             keywords: 'arcc algorand',
             itemTitle: 'Arcc',
           },
           icon: 'portofolio-arcc.svg',
         },
         {
           path: '/portfoliodetail#avocado',
           component: PortfolioDetail,
           meta: {
             title: 'Avocado Guild - Undercurrent Tech',
             group: 'projects',
             keywords: 'avocado',
             itemTitle: 'Avocado Guild',
           },
           icon: 'portfolio-icon-avocado-guild.png',
         },
         {
           path: '/portfoliodetail#carfren',
           component: PortfolioDetail,
           meta: {
             title: 'Carfren - Undercurrent Tech',
             group: 'projects',
             keywords: 'carfren cars insurance',
             itemTitle: 'Carfren',
           },
           icon: 'portofolio-carfren.svg',
         },
         {
           path: '/portfoliodetail#mesedex',
           component: PortfolioDetail,
           meta: {
             title: 'Mese Dex - Undercurrent Tech',
             group: 'projects',
             keywords: 'mese dex algorand',
             itemTitle: 'Mese Dex',
           },
           icon: 'portofolio-mese.svg',
         },
         {
           path: '/portfoliodetail#mesewallet',
           component: PortfolioDetail,
           meta: {
             title: 'Mese Wallet - Undercurrent Tech',
             group: 'projects',
             keywords: 'mese wallet algorand',
             itemTitle: 'Mese Wallet',
           },
           icon: 'portofolio-mese.svg',
         },
         {
           path: '/portfoliodetail#mysginsider',
           component: PortfolioDetail,
           meta: {
             title: 'MySGInsider - Undercurrent Tech',
             group: 'projects',
             keywords: 'mysginsider travel',
             itemTitle: 'MySGInsider',
           },
           icon: 'portofolio-mysginsider-2.svg',
         },
         {
           path: '/portfoliodetail#thetanuts',
           component: PortfolioDetail,
           meta: {
             title: 'Theta Nuts - Undercurrent Tech',
             group: 'projects',
             keywords: 'thetanuts',
             itemTitle: 'Theta Nuts',
           },
           icon: 'portfolio-icon-thetanuts.png',
         },
       ];

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  routes,
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0, y: 0 };
  // }
})

// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]'),
  ).map((el) => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta')

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key])
      })

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '')

      return tag
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag))
    
  next()
})

// ...

export default router
