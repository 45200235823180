<template>
  <div class="container-fluid" id="company-section">
    <div class="box-1 box-top">
      <div class="box-2">
        <div class="row no-gutters align-items-center justify-content-center">
          <div class="col-12 col-sm-12 col-md-12 col-lg-12">
            <div class="box-3 box-3-nomargin">
              <h3>Who We Are</h3>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6 col-lg-6">
            <div
              class="phone-image col-12 col-sm-12 col-md-6 col-lg-6 d-flex d-sm-none"
            >
              <img src="../../assets/about-us.png" />
            </div>

            <div
              class="col-12 col-sm-12 col-md-12 col-lg-12 who-we-are-description"
            >
              <div class="box-3 box-3-nomargin">
                <p>
                  We believe in busting boundaries and empowering innovators to
                  think differently.
                </p>
                <p>
                  We work with clients to implement technology that transfers
                  information on a scale and at a level of convenience that
                  can’t be replicated elsewhere – and plan with them to
                  translate those advantages into economic value.
                </p>
                <p>
                  We are a bespoke technology solutions firm dedicated to
                  helping organisations embrace technology as a transformational
                  force, not just for solving incremental problems. We provide
                  deep operational support - from inception through all phases
                  of growth - to rapidly launch products and services, create
                  new business models, and prepare leadership and culture for
                  the future.
                </p>
              </div>
            </div>
          </div>

          <div
            class="phone-image col-12 col-sm-12 col-md-6 col-lg-6 d-none d-sm-flex"
          >
            <img src="../../assets/about-us.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../assets/styles/AboutUs.scss';

.who-we-are-description {
  overflow-y: auto;
  height: 35vh;

  @media only screen and (min-width: 768px) {
    height: auto;
  }
}
.header-text {
  font-family: 'Bebas Kai';
}
p {
  font-family: 'Manrope';
}

.phone-image {
  order: -1;

  @media only screen and (min-width: 768px) {
    order: 2;
  }
}
.who-we-are-title {
  order: -2;

  .box-3 {
    /* padding-top: 80px; */

    @media only screen and (min-width: 768px) {
      padding-top: 0;
    }
  }

  @media only screen and (min-width: 768px) {
    order: -1;
  }
}
/* .box-top {
  padding-top: 80px !important;

  @media only screen and (min-width: 768px) {
    padding-top: 10.277777777777779vw !important;
  }
} */
.box-top {
  margin-top: 70px !important;

  @media only screen and (min-width: 768px) {
    /* margin-top: 10.277777777777779vw !important; */
    margin-top: 6.111111111111111vw !important;
    padding-top: 3.4vw !important;
  }
}
</style>
