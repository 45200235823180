
let actions = {
  toggleMenu({ state, commit }, menu = null) {
    if (menu !== null) {
      commit('SET_MENU_OPEN', menu);
      return;
    }

    commit('SET_MENU_OPEN', !state.isMenuOpen);
  },
  openSearchBox({ state, commit }, enable = null) {
    if (enable !== null) {
      commit('SET_OPEN_SEARCH_BOX_CUSTOM', enable);
      return;
    }

    if (state.searchBoxEnable) {
      commit('SET_OPEN_SEARCH_BOX_TRUE');
    } else {
      commit('SET_OPEN_SEARCH_BOX_FALSE');
    }
  },
  doSpeechSearch({commit}, isOpen) {
    if (!isOpen) {
      commit('SET_SPEECH_SEARCH_TRUE');
    } else {
      commit('SET_SPEECH_SEARCH_FALSE');
    }
  },
};

export default actions;
