<template>
  <div class="container-snap" id="head-snap">
    <div
      v-if="sectionPage === 1"
      class="container-fluid section-snap snap-1"
      id="screen-1"
    >
      <div class="box-1">
        <div class="row align-items-center justify-content-center" id="arcc">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="carfren-project-text">
              <h1 class="sc1-text-1">
                ARCC.ONE
              </h1>
              <small class="subtitle-text">algorand blockchain platform</small>
              <div class="project-detail-text row align-items-start">
                <div class="col-sm-12 col-md-12 col-lg-12 sc1-text-2">
                  <!-- <div class="row">
                    <div class="col-3 label-title">
                      <h6>Founder</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>Sinjin David Jung</h6>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-3 label-title">
                      <h6>Website</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>
                        <a href="http://www.arcc.one">www.arcc.one</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-12 description-box">
                  <p>
                    The Asia Reserve Currency Coin, ARCC, introduces a new
                    economic system designed with the world’s first
                    ‘microasset,’ as a tool for economic development in emerging
                    markets. Their mission is to enable financial inclusion of
                    the urban working poor through access to debt-free capital
                    for entrepreneurial investment.
                    <br /><br />

                    The platform consists of a digital ID with Single Sign-On
                    (SSO) across apps, cryptocurrency wallet, trade functions
                    between tokens, survey questions &amp; results display, VIP
                    system, referral program, Sunday savings, and
                    angel-crowdfunding for businesses.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-11 col-sm-12 col-md-6 col-lg-6">
            <img
              id="thecar"
              src="../assets/portofolio-arcc-2.png"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="sectionPage === 2"
      class="container-fluid section-snap snap-2"
      id="screen-1"
    >
      <div class="box-1">
        <div class="row align-items-center justify-content-center" id="carfren">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="carfren-project-text">
              <h1 class="sc1-text-1">
                AVOCADO GUILD
              </h1>
              <small class="subtitle-text"
                >Member payment management system for play-to-earn guild</small
              >
              <div class="project-detail-text row align-items-start">
                <div class="col-sm-12 col-md-12 col-lg-12 sc1-text-2">
                  <!-- <div class="row">
                    <div class="col-3 label-title">
                      <h6>FOUNDER</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>Jonathan Kheng</h6>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-3 label-title">
                      <h6>Website</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>
                        <a href="http://www.avocadoguild.com"
                          >www.avocadoguild.com</a
                        >
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-12 description-box">
                  <p>
                    Avocado Guild is a play-to-earn guild that aims to unlock
                    life-changing opportunities and rewarding experiences for
                    its community members. <br /><br />

                    Managing thousands of members on a daily basis, Avocado
                    Guild needed an automated solution for revenue claiming and
                    payments disbursement to break through the ceiling of how
                    many guild members they could effectively manage.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-11 col-sm-12 col-md-6 col-lg-6">
            <img
              id="thecar"
              src="../assets/portfolio-avocado-guild-2.png"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="sectionPage === 3"
      class="container-fluid section-snap snap-2"
      id="screen-1"
    >
      <div class="box-1">
        <div class="row align-items-center justify-content-center" id="carfren">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="carfren-project-text">
              <h1 class="sc1-text-1">
                CARFREN
              </h1>
              <small class="subtitle-text"
                >Automobile management for car owners</small
              >
              <div class="project-detail-text row align-items-start">
                <div class="col-sm-12 col-md-12 col-lg-12 sc1-text-2">
                  <!-- <div class="row">
                    <div class="col-3 label-title">
                      <h6>FOUNDER</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>Jonathan Kheng</h6>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-3 label-title">
                      <h6>Website</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>
                        <a href="http://WWW.CARFREN.COM.SG"
                          >WWW.CARFREN.COM.SG</a
                        >
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-12 description-box">
                  <p>
                    CarFren is a platform for car owners by car owners that
                    empowers consumers with choice, offering transparent options
                    with honest transactions, and addressing information
                    asymmetry is at the heart of a customer-oriented platform.
                    <br /><br />

                    Car owners can transparently shop for insurance and
                    warranties, view their policies, report accidents, book
                    servicing appointments, and keep track of their servicing
                    history. All efficiently handled on a cutting edge digital
                    platform. No more obscurity, no more middle-men or
                    questionable tactics.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-11 col-sm-12 col-md-6 col-lg-6">
            <img
              id="thecar"
              src="../assets/portofolio-carfren-2.png"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="sectionPage === 4"
      class="container-fluid section-snap snap-3"
      id="screen-1"
    >
      <div class="box-1">
        <div class="row align-items-center justify-content-center" id="mesedex">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="carfren-project-text">
              <h1 class="sc1-text-1">
                MESE DEX
              </h1>
              <small class="subtitle-text"
                >Decentralized exchange on algorand blockchain</small
              >
              <div class="project-detail-text row align-items-start">
                <div class="col-sm-12 col-md-12 col-lg-12 sc1-text-2">
                  <!-- <div class="row">
                    <div class="col-3 label-title">
                      <h6>Founder</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>Sinjin David Jung</h6>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-3 label-title">
                      <h6>Website</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>
                        <a href="http://dex.MESE.IO">dex.MESE.IO</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-12 description-box">
                  <p>
                    Backed by the International Blockchain Monetary Reserve and
                    supported by the Algorand Foundation, MESE is about
                    accessibility to those who would benefit the most from open
                    direct accessible finance. <br /><br />

                    MESE DEX is a straightforward and simple to use
                    decentralized exchange on the Algorand Network.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-11 col-sm-12 col-md-6 col-lg-6">
            <img
              id="thecar"
              src="../assets/portofolio-mese-dex-2.png"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="sectionPage === 5"
      class="container-fluid section-snap snap-3"
      id="screen-1"
    >
      <div class="box-1">
        <div
          class="row align-items-center justify-content-center"
          id="mesewallet"
        >
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="carfren-project-text">
              <h1 class="sc1-text-1">
                MESE Wallet
              </h1>
              <small class="subtitle-text"
                >Web3 algorand blockchain wallet</small
              >
              <div class="project-detail-text row align-items-start">
                <div class="col-sm-12 col-md-12 col-lg-12 sc1-text-2">
                  <!-- <div class="row">
                    <div class="col-3 label-title">
                      <h6>Founder</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>Sinjin David Jung</h6>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-3 label-title">
                      <h6>Website</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>
                        <a href="http://wallet.MESE.IO">wallet.MESE.IO</a>
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-12 description-box">
                  <p>
                    Backed by the International Blockchain Monetary Reserve and
                    supported by the Algorand Foundation, MESE is about
                    accessibility to those who would benefit the most from open
                    direct accessible finance. <br /><br />

                    MESE DEX is a straightforward and simple to use
                    decentralized exchange on the Algorand Network.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-11 col-sm-12 col-md-6 col-lg-6 rightbox-img-container"
          >
            <img
              id="thecar"
              src="../assets/portofolio-mese-wallet-2.svg"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="sectionPage === 6"
      class="container-fluid section-snap snap-4"
      id="screen-1"
    >
      <div class="box-1">
        <div
          class="row align-items-center justify-content-center"
          id="mysginsider"
        >
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="carfren-project-text">
              <h1 class="sc1-text-1">
                MYSGINSIDER
              </h1>
              <small class="subtitle-text"
                >Singapore’s travel guidance platform</small
              >
              <div class="project-detail-text row align-items-start">
                <div class="col-sm-12 col-md-12 col-lg-12 sc1-text-2">
                  <!-- <div class="row">
                    <div class="col-3 label-title">
                      <h6>Founder</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>KAY SWEE PIN</h6>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-3 label-title">
                      <h6>Website</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>
                        <a href="http://www.mysginsider.com"
                          >www.mysginsider.com</a
                        >
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-12 description-box">
                  <p>
                    Our best trips have always been to locations where a local
                    friend gave us tips on the places locals frequented and the
                    activities they got up to. But for the better part of the
                    internet generation, travel websites have been written by
                    tourists for other tourists. <br /><br />

                    mySGinsider is a one-stop resource by local insiders for
                    everything to see, eat, shop and do in Singapore. Vouchers
                    can be purchased for savings with various merchants, or
                    earned by generating user content and sharing it on the
                    platform. They believe in empowering user experiences, and
                    as our elders taught us: good things must share!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-11 col-sm-12 col-md-6 col-lg-6">
            <img
              id="thecar"
              src="../assets/portofolio-mysginsider-2.png"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="sectionPage === 7"
      class="container-fluid section-snap snap-4"
      id="screen-1"
    >
      <div class="box-1">
        <div
          class="row align-items-center justify-content-center"
          id="mysginsider"
        >
          <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="carfren-project-text">
              <h1 class="sc1-text-1">
                Theta Nuts
              </h1>
              <small class="subtitle-text"
                >Defi protocol for structured cryptocurrency products</small
              >
              <div class="project-detail-text row align-items-start">
                <div class="col-sm-12 col-md-12 col-lg-12 sc1-text-2">
                  <!-- <div class="row">
                    <div class="col-3 label-title">
                      <h6>Founder</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>KAY SWEE PIN</h6>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-3 label-title">
                      <h6>Website</h6>
                    </div>
                    <div class="col-5 label-subtitle">
                      <h6>
                        <a href="http://www.thetanuts.finance"
                          >www.thetanuts.finance</a
                        >
                      </h6>
                    </div>
                  </div>
                </div>
                <div class="col-12 description-box">
                  <p>
                    Thetanuts is a Defi protocol that provides vaults with
                    various options strategies. It was designed with the
                    everyday person in mind who does not have the tools or
                    ability to take advantage of these strategies.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-11 col-sm-12 col-md-6 col-lg-6">
            <img
              id="thecar"
              src="../assets/portfolio-thetanuts.png"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="add-screen"></div>
  </div>
</template>

<script>
import ScrollMagic from 'scrollmagic';
import TweenMax, { TimelineMax } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
ScrollMagicPluginGsap(ScrollMagic, TweenMax);

export default {
  name: 'Project-carfren',
  data() {
    return {
      sectionPage: 1,
      scrollPosition: 0,
      firstLoad: false,
      wct1_doAnimation: true,
      wc_t1_opacity_0: false,
    };
  },
  props: {
    result_item: Array,
  },
  mounted: function() {
    window.addEventListener('scroll', this.updateScroll);

    // const controller_car = new ScrollMagic.Controller();
    // var tween = TweenMax.to('#thecar', 2, { x: 150, opacity: 1, delay: 1.6 });
    // var scene_car = new ScrollMagic.Scene({
    //   triggerElement: '#trigger-car',
    //   triggerHook: 0.95,
    //   duration: 500,
    //   offset: 100,
    // })

    //   .setTween(tween)
    //   .addTo(controller_car);

    let hash = window.location.hash;
    let scrollHeight = window.document.scrollingElement.scrollHeight;
    let clientHeight = window.document.scrollingElement.clientHeight;

    let totalScrollable = scrollHeight - clientHeight;
    let totalItem = 7; // IMPORTANT! PLEASE CHANGE THIS IF THERE'S NEW ITEM

    // total scroll divide total of each section
    let eachScroll = totalScrollable / totalItem;

    if (hash) {
      if (hash == '#arcc') {
        window.scrollTo(0, 0);
      }
      if (hash == '#avocado') {
        window.scrollTo(0, eachScroll + 1);
      }

      if (hash == '#carfren') {
        window.scrollTo(0, eachScroll * 2 + 1);
      }

      if (hash == '#mesedex') {
        window.scrollTo(0, eachScroll * 3 + 1);
      }

      if (hash == '#mesewallet') {
        window.scrollTo(0, eachScroll * 4 + 1);
      }

      if (hash == '#mysginsider') {
        window.scrollTo(0, eachScroll * 5 + 1);
      }

      if (hash == '#thetanuts') {
        window.scrollTo(0, eachScroll * 6 + 1);
      }
    } else {
      window.scrollTo(0, 0);
    }
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.updateScroll);
  },
  methods: {
    updateScroll(e) {
      this.scrollPosition = e.target.documentElement.scrollTop;
      let scrollHeight = e.target.documentElement.scrollHeight;
      let clientHeight = e.target.documentElement.clientHeight;

      let totalScrollable = scrollHeight - clientHeight;
      let totalItem = 7; // IMPORTANT! PLEASE CHANGE THIS IF THERE'S NEW ITEM

      // total scroll divide total of each section
      let eachScroll = totalScrollable / totalItem;

      if (this.scrollPosition < eachScroll) {
        this.sectionPage = 1;
      } else if (
        this.scrollPosition >= eachScroll &&
        this.scrollPosition < eachScroll * 2
      ) {
        this.sectionPage = 2;
      } else if (
        this.scrollPosition >= eachScroll * 2 &&
        this.scrollPosition < eachScroll * 3
      ) {
        this.sectionPage = 3;
      } else if (
        this.scrollPosition >= eachScroll * 3 &&
        this.scrollPosition < eachScroll * 4
      ) {
        this.sectionPage = 4;
      } else if (
        this.scrollPosition >= eachScroll * 4 &&
        this.scrollPosition < eachScroll * 5
      ) {
        this.sectionPage = 5;
      } else if (
        this.scrollPosition >= eachScroll * 5 &&
        this.scrollPosition < eachScroll * 6
      ) {
        this.sectionPage = 6;
      } else if (this.scrollPosition >= eachScroll * 6) {
        this.sectionPage = 7;
      }
      //console.log(this.sectionPage);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/styles/projects/portofoliodetail.scss';

.container-snap {
  position: relative;
  width: 100%;
  height: 100vh;
}

.project-detail-text {
  padding-top: 10px;

  @media only screen and (min-width: 1440px) {
    padding-top: 45px;
  }
}
#screen-1 {
  width: 83.33333333333333vw;
  /* height: calc(100vh - 88px); */
  height: 100vh;
  /* background: url('../assets/projects/carfren/screen-1.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  /* padding-top: 4vh !important; */
  padding: 5.625vw;
  padding-top: 30px;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  /* padding: 0 6.333333333333334vw; */
  padding: 0 5.333333333333334vw;
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.02) 30.21%,
    rgba(255, 255, 255, 0.1) 49.48%,
    rgba(255, 255, 255, 0.02) 69.27%,
    rgba(255, 255, 255, 0) 100%,
    rgba(255, 255, 255, 0) 100%
  );
  mix-blend-mode: normal;
  backdrop-filter: blur(30px);

  @media only screen and (min-width: 992px) {
    padding-top: 10.277777777777779vw;

    .row .col-lg-6 ~ .col-lg-6 {
      flex-basis: 30.555555555555557vw;
      width: 30.555555555555557vw;
      margin: auto;
    }
  }

  @media only screen and (min-width: 1440px) {
    /* padding-top: 4.166666666666667vw; */
  }
}
#screen-2 {
  width: 86.11111111111111vw;
  height: calc(100vh - 88px);
  /* background: url('../assets/projects/carfren/screen-2.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  padding: 5.625vw;
  padding-top: 30px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);

  @media only screen and (min-width: 1440px) {
    padding-top: 5.625vw;
  }
}
#screen-3 {
  width: 86.11111111111111vw;
  height: calc(100vh - 88px);
  /* background: url('../assets/projects/carfren/screen-2.png'); */
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  padding: 5.625vw;
  padding-top: 30px;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);

  @media only screen and (min-width: 1440px) {
    padding-top: 5.625vw;
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    margin-left: -10vh;
    margin-top: -10vh;
    background: linear-gradient(90deg, #1f3647, transparent);
  }
}
.add-screen {
  width: 80%;
  height: calc(400vh - 88px);
  display: block;
  padding: 10vh;
}
.anim1 {
  @include animate_1;
}
.anim2 {
  @include animate_2;
}
.anim3 {
  @include animate_3;
}
.anim4 {
  @include animate_screen;
}

.snap-1,
.snap-3,
.snap-4,
.snap-2 {
  @include animate_screen;
}

.sc1-text-1 {
  @include animate_1;
  font-family: 'Bebas Kai';
  font-weight: 200;
  font-size: 42px;
  line-height: 50.4px;
  letter-spacing: 0.05em;
  text-shadow: 0px 5px 5px rgba(63, 63, 63, 0.4);

  @media only screen and (min-width: 1440px) {
    font-size: 3.4722222222222223vw;
    line-height: 4.166666666666667vw;
  }
}

.subtitle-text {
  font-size: 14px;
  line-height: 14px;
  font-family: 'Bebas Kai';
  font-weight: 200;
  letter-spacing: 0.05em;

  @media only screen and (min-width: 1440px) {
    font-size: 0.9722222222222222vw;
    line-height: 0.9722222222222222vw;
  }
}

#screen-1 .sc1-text-2 {
  @include animate_2;
}
#screen-1 .sc1-text-3 {
  @include animate_3;
}

#sc2-img-1,
#sc2-img-2,
#sc3-text-1 {
  @include animate_1;
}
#sc2-text-1,
#sc2-text-3,
#sc3-text-2 {
  @include animate_2;
}
#sc2-text-2,
#sc2-text-4 {
  @include animate_3;
}
.label-title > * {
  font-family: 'Bebas Neue';
  font-size: 14px;
  line-height: initial;
  opacity: 0.4;

  @media only screen and (min-width: 1440px) {
    font-size: 1.2vw;
    font-size: 1.1111111111111112vw;
    line-height: 1.5180555555555555vw;
    letter-spacing: 0.08em;
  }
}
.label-subtitle > * {
  font-family: 'Bebas Kai';
  font-size: 14px;
  line-height: initial;

  @media only screen and (min-width: 1440px) {
    font-size: 1.2vw;
    font-size: 1.1111111111111112vw;
    line-height: 1.5180555555555555vw;
    letter-spacing: 0.08em;
  }
}
a {
  color: white;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.description-box {
  margin-top: 2.7777777777777777vw;

  p {
    font-size: 11px;
    line-height: initial;
    letter-spacing: 0.05em;
    text-align: justify;

    @media only screen and (min-width: 768px) {
      font-size: 12px;
      line-height: initial;
    }

    @media only screen and (min-width: 1440px) {
      font-size: 1.1111111111111112vw;
      line-height: 1.5180555555555555vw;
    }
  }
}
.rightbox-img-container {
  @media only screen and (min-width: 768px) {
    padding-left: 30px;
  }
  @media only screen and (min-width: 1440px) {
    padding-left: 60px;
  }
}
.box-1 {
  padding: 0;
  padding-top: 80px;

  @media only screen and (min-width: 992px) {
    padding-top: 0;
  }
}
</style>
