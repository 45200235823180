let mutations = {
  SET_MENU_OPEN(state, menu) {
    state.isMenuOpen = menu;
  },
  SET_OPEN_SEARCH_BOX_CUSTOM(state, value) {
    state.searchBoxEnable = value;
    state.searchCircle = value;
    state.WelcomeMessage = value;
    state.wct2_doAnimation = value;
    state.wct2_doAnimation_delay = value;
    state.sbox_doAnimation = value;
    state.sbox_doAnimation_delay = value;
  },
  SET_OPEN_SEARCH_BOX_TRUE(state) {
    state.searchBoxEnable = false;
    state.searchCircle = false;
    state.WelcomeMessage = false;
    state.wct2_doAnimation = false;
    state.wct2_doAnimation_delay = false;
    state.sbox_doAnimation = false;
    state.sbox_doAnimation_delay = false;
  },
  SET_OPEN_SEARCH_BOX_FALSE(state) {
    state.searchBoxEnable = true;
    state.searchCircle = true;
    state.wct2_doAnimation = true;
    state.sbox_doAnimation = true;
  },
  SET_WELCOME_MESSAGE(state, value) {
    state.WelcomeMessage = value;
  },
  SET_ANIMATION_HOME_TRUE(state) {
    state.WelcomeMessage = false;
    state.wct2_doAnimation = false;
    state.wct2_doAnimation_delay = false;
    state.sbox_doAnimation = false;
    state.sbox_doAnimation_delay = false;
  },
  SET_ANIMATION_HOME_FALSE(state) {
    state.wct1_doAnimation = true;
    state.wc_t1_opacity_0 = false;
    state.searchCircle = true;
    state.searchBoxEnable = true;
    state.WelcomeMessage = true;
    state.wct2_doAnimation = false;
    state.wct2_doAnimation_delay = true;
    state.sbox_doAnimation = false;
    state.sbox_doAnimation_delay = true;
    state.wct2_opacity_0 = false;
    state.speechSearch = false;
    state.prevRoute = null;
  },
  SET_SPEECH_SEARCH_TRUE(state) {
    state.speechSearch = false;
    state.wct2_opacity_0 = false;
  },
  SET_SPEECH_SEARCH_FALSE(state) {
    state.speechSearch = true;
    state.inputPlaceholder = '';
    state.searchBoxInput = '';
    state.wct2_opacity_0 = true;
  },
  SET_PREV_ROUTE(state, value) {
    state.prevRoute = value;
  },
};

export default mutations;