<template>
  <div class="container-fluid" id="portofolio-section">
    <div class="box-1 box-top">
      <div class="box-2">
        <div
          class="row no-gutters align-items-center justify-content-center align-content-start"
          style="height: 100%;"
        >
          <div class="col-12">
            <div class="box-3 box-3-nomargin">
              <h3>Portfolio</h3>
            </div>
          </div>

          <div class="row no-gutters box-3 box-3-nomargin portofolio-items">
            <router-link
              v-for="(item, index) in items"
              :key="index"
              :to="item.link"
            >
              <div
                class="col-6 col-md-3 col-lg-3 d-flex align-content-center justify-content-center"
              >
                <div class="noise-overlay"></div>
                <img :src="getImage(item.img)" />
                <h3 class="card-title">
                  {{ item.title }}
                </h3>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: 'ARCC',
          img: 'portofolio-arcc.svg',
          link: '/portfoliodetail#arcc',
        },
        {
          title: 'Avocado Guild',
          img: 'portfolio-icon-avocado-guild.png',
          link: '/portfoliodetail#avocado',
        },
        {
          title: 'Carfren',
          img: 'portofolio-carfren.svg',
          link: '/portfoliodetail#carfren',
        },
        {
          title: 'Mese Dex',
          img: 'portofolio-mese.svg',
          link: '/portfoliodetail#mesedex',
        },
        {
          title: 'Mese Wallet',
          img: 'portofolio-mese.svg',
          link: '/portfoliodetail#mesewallet',
        },
        {
          title: 'MYSGINSIDER',
          img: 'portofolio-mysginsider.svg',
          link: '/portfoliodetail#mysginsider',
        },
        {
          title: 'Theta Nuts',
          img: 'portfolio-icon-thetanuts.png',
          link: '/portfoliodetail#thetanuts',
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      var images = require.context('../../assets/portofolio/', false);
      return images('./' + img);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Portofolio.scss';

.text-head-cont {
  /* position: absolute; */
  /* left: 11%; */
  font-family: 'Bebas Kai';
  font-style: normal;
  /* font-weight: bold; */
  color: #ffffff;
  font-size: 42px;
  line-height: 50px;
  margin-bottom: 2rem;
  letter-spacing: 0.05em;

  @media only screen and (min-width: 992px) {
    font-size: 3.4722222222222223vw;
    line-height: 4.166666666666667vw;
    margin-bottom: 0;
  }
}
.ads-text {
  position: relative;
  margin: 8px 5px 0;
  padding: 5px 0 5px 15px;
  background-color: #ffffff;
  border-radius: 0.3rem;
  z-index: 1;
}
.title-ads {
  font-size: 8px;
  font-style: normal;
  font-weight: normal;
  color: #000000;
  width: 100%;
  //margin-bottom: 3px;
  text-align: left;
}
.sub-ads {
  font-size: 10px;
  font-style: normal;
  font-weight: normal;
  color: #007aff;
  width: 100%;
  text-align: left;
}
.line-ads {
  width: 100%;
  height: 1px;
  margin: 5px 0;
  background-color: #dbdcdd;
}
.map-ads {
  position: relative;
  min-height: 60px;
  width: 100%;
  height: 100%;
}
.maps-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  //right: 0;
  min-height: 60px;
  width: 95%;
  height: 100%;
}
.c-btn {
  padding: 0 5px !important;
}
.btn-contact,
.btn-contact:hover,
.btn-contact:focus,
.btn-contact:active {
  width: 100%;
  padding: 2px 1rem;
  font-size: 8px;
  font-style: normal;
  font-weight: bold;
  border-radius: 0.3rem;
  background-color: #ffffff;
  border-color: #ffffff;
  color: #007aff;
  box-shadow: none !important;
  outline: transparent !important;
}
.phone-image {
  text-align: center;
  //margin-top: 88px;
  // background-image: url(/img/phone-contact.58987270.svg);
  // background-repeat: no-repeat;
  // background-position: bottom -1px center;
  // height: calc(100vh - 88px);
  // background-size: 308px 545.14px;
  //background-size: 100% 100%;
}

#portofolio-section {
  padding: 0;

  @media only screen and (min-width: 768px) {
    padding: 0 8.333333333333334vw;
  }

  .row a {
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    flex: 0 0 50%;
    width: 50%;
    justify-content: space-between;

    @media only screen and (min-width: 992px) {
      &:not(:last-child) {
        justify-content: initial;
        margin-right: 2.7777777777777777vw; // 40px in 1440px
      }
    }

    &:nth-child(odd) {
      padding-left: 0;
    }
    &:nth-child(even) {
      padding-right: 0;
    }

    @media only screen and (min-width: 768px) {
      flex: 0 0 25%;
      width: 25%;
      padding-left: 0;
      padding-right: 20px;
      padding-bottom: 30px;

      &:nth-child(even) {
        padding-right: 20px;
      }
    }

    @media only screen and (min-width: 992px) {
      flex: 0 0 21%;
      width: 21%;
      padding-bottom: 30px;
    }

    @media only screen and (min-width: 1440px) {
      padding-bottom: 2.0833333333333335vw;
    }

    > div {
      position: relative;
      /* padding: 5.208333333333333vw; */
      padding: 10px;
      width: 100%;
      justify-content: center;
      align-items: center;
      /* width: 199.77px;
      height: 250px; */
      border-radius: 24px;
      flex-direction: column;
      backdrop-filter: blur(140px);
      transition: all ease 0.5s;
      background-image: url('../../assets/card-shape.png');
      background-size: cover;
      background-repeat: no-repeat;
      aspect-ratio: 4/5;

      @media only screen and (min-width: 1440px) {
        /* width: 13.872916666666667vw;
        height: 17.36111111111111vw; */
        border-radius: 1.6666666666666667vw;
      }

      &:hover {
        background: linear-gradient(
          126.6deg,
          rgba(255, 255, 255, 0.12) 28.69%,
          rgba(255, 255, 255, 0) 100%
        );
      }

      .noise-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('../../assets/noise.png');
        border-radius: 24px;

        @media only screen and (min-width: 1440px) {
          border-radius: 1.6666666666666667vw;
        }
      }
    }

    img {
      /* width: 100%;
      height: 100%; */
      /* width: 21.333333333333332vw;
      height: 21.333333333333332vw; */
      width: 80px;
      height: 80px;

      @media only screen and (min-width: 992px) {
        width: 6.944444444444445vw;
        height: 6.944444444444445vw;
      }
    }
  }
}
.portofolio-items {
  overflow-y: auto;
  margin-top: 30px;
  height: 70vh;

  @media only screen and (min-width: 992px) {
    height: 28.47222222222222vw;
  }

  /* Scrollbar */

  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    margin-left: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    // background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(136, 136, 136, 0.05);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(85, 85, 85, 0.05);
  }

  @media only screen and (min-width: 1440px) {
    /* margin-top: 3.4722222222222223vw; */
    /* margin-top: 100px; */
    margin-top: 2.0833333333333335vw;
  }

  .card-title {
    font-family: 'Bebas Kai';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20.8px;
    /* font-size: 24px;
    line-height: 28.8px; */
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0px 5px 5px rgba(63, 63, 63, 0.4);
    position: relative;
    /* top: 1.0416666666666667vw; */
    top: 20px;

    @media only screen and (min-width: 992px) {
      font-size: 1.6666666666666667vw;
      line-height: 2vw;
      /* margin-top: 30px; */
      top: 1.0416666666666667vw;
      text-align: center;
    }

    @media only screen and (min-width: 1440px) {
      /* margin-top: 50px; */
      top: 1.3888888888888888vw;
    }
  }
}
.box-top {
  margin-top: 70px !important;

  @media only screen and (min-width: 768px) {
    /* margin-top: 10.277777777777779vw !important; */
    margin-top: 6.111111111111111vw !important;
    padding-top: 3.4vw !important;
  }
}
</style>
