import { createStore } from 'vuex';

// for 'global' usage
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from './state';

// please make seperate module if needed
// import home from './modules/home'


const store = createStore({
  modules: {},
  state,
  mutations,
  getters,
  actions,
});



export default store;